import React from "react";
import "./ServiceInner.css";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faCirclePlay, faFilePdf } from "@fortawesome/free-regular-svg-icons";
import PurityCard from "../../CommonComponents/PurityCard/PurityCard";
import { Link } from "react-router-dom";
function ServiceInner() {
  const text1 =
    "Nunc pharetra magna orci, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.  Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea .";
  
  const text2 ="In eget rhoncus massa, sit amet sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata Duis a libero nec enim luctus congue.";
    const data = [
    {
      name: "Water Testing",
    },
    {
      name: "Water Trailers",
    },
    {
      name: "Cosmetic Dentistry",
    },
    {
      name: "Water Dispenser",
    },
    {
      name: "Bottled Water",
    },
  ];

  const data2 = [
    {
      image: process.env.PUBLIC_URL + "/assets/Image/service/water.png",
      name: "Maximum Purity",
      text: "Cras nec metus velit. Quisque sit amet justo id sapien rhoncus pharetra sed quis mi.",
    },
    {
      image: process.env.PUBLIC_URL + "/assets/Image/service/glasswater.png",
      name: "5 Steps Filtration",
      text: "Cras nec metus velit. Quisque sit amet justo id sapien rhoncus pharetra sed quis mi.",
    },
  ];

  return (
    <>
      <section className="serviceinner_section mt-4 mb-4 ">
        <Container>
          <div className="service_div_main">
            <Row>
              <Col xxl={4} xl={4} lg={4} md={12} sm={12} className="mb-4">
                <div className="first-blog-service">
                  <h6 className="blog-common-title">Popular Services</h6>

                  {data.map((item, index) => (
                    <div className="play_div mb-3">
                      <p className="midtext mb-0">{item.name}</p>
                      <FontAwesomeIcon
                        icon={faCirclePlay}
                        className="circleicn"
                      />
                    </div>
                  ))}

                  <div className="pdf_div mb-3">
                    <div className="rightpdf_div">
                      <p className="midtext mb-0">Company Profile</p>
                      <p className="sub-txt mb-0">Download PDF</p>
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faFilePdf} className="pdficn" />
                    </div>
                  </div>
                  <div className="pdf_div mb-3">
                    <div className="rightpdf_div">
                      <p className="midtext mb-0">Company Broucher</p>
                      <p className="sub-txt mb-0">Download PDF</p>
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faFilePdf} className="pdficn" />
                    </div>
                  </div>
                </div>
              </Col>
              <Col xxl={8} xl={8} lg={8} md={12} sm={12} className="mb-4">
                <div className="details_div">
                  <Link to='/service'><p className="text-end sub-txt">
                    <FontAwesomeIcon icon={faArrowLeft} /> Back
                  </p></Link>

                  <div className="innerimg_div">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Image/service/girlbottle.png"
                      }
                      className="inner_imgs"
                    />
                  </div>
                  <div className="mb-3">
                    <h6 className="blog-common-title">
                      <span>S</span>ystem Repair{" "}
                    </h6>
                    <p className="sub-txt">{text1}</p>
                    <p className="sub-txt">{text2}</p>
                  </div>
                  <div>
                    <Row>
                    {data2.map((item, index) => (
                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} className="mb-2" >
                        <div>
                            <PurityCard item={item}/>
                        </div>
                        </Col>
                    ))}
                       
                    </Row>
                  </div>
                  <div className="qualityas">
                    <Row>
                      <Col
                        xxl={5}
                        xl={5}
                        lg={6}
                        md={6}
                        sm={12}
                        className="mb-3"
                      >
                        <div className="subimg_div">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Image/service/girlbottle.png"
                            }
                            className="sub_imgs"
                          />
                        </div>
                      </Col>
                      <Col
                        xxl={7}
                        xl={7}
                        lg={6}
                        md={6}
                        sm={12}
                        // className=" my-auto"
                      >
                        <div className="">
                          <h4 className="blog-common-title">
                            Quality Control System
                          </h4>
                          <p className="sub-txt">
                            Aliquam sed ullamcorper dolor.consetetur sadipscing
                            elitr, sed diam nonumy eirmod tempor invidunt ut
                            labore et dolore magna aliquyam erat, sed diam
                            voluptua. At vero eos et accusam et
                          </p>
                          <h4 className="blog-common-title">
                            Highly Professional Staff
                          </h4>
                          <p className="sub-txt mb-0">
                            Morbi eget nulla justo. Curabitur in purus in lacus
                            commodo odio posuere egestas.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <h6 className="blog-common-title">
                      Fermentum purus fermentum
                    </h6>
                    <p className="sub-txt">
                      Praesent ut ex tincidunt, consetetur sadipscing elitr, sed
                      diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua. At vero eos et
                      accusam et justo duo dolores et ea rebum. Stet clita kasd
                      gubergren, no sea takimata sanctus est Lorem ipsum dolor
                      sit amet. Lorem ipsum dolor sit amet, consetetur
                      sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                      ut labore et dolore magna aliquyam erat, sed diam
                      voluptua. At vero eos et accusam et justo duo dolores et
                      ea 
                    </p>
                    <p className="sub-txt">
                    Quisque fringilla magna justo, consetetur sadipscing
                      elitr, sed diam nonumy eirmod tempor invidunt ut labore et
                      dolore magna aliquyam erat, sed diam voluptua. At vero eos
                      et accusam et justo duo dolores et ea rebum. Stet clita
                      kasd gubergren, no sea takimata sanctus est Lorem ipsum
                      dolor sit amet. Lorem
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
}

export default ServiceInner;

import React from 'react'
import './Certification.css'
import { Col, Container, Row } from 'react-bootstrap'

const Certification = () => {
  return (
    <section className='main-ceritification'>
      <div className='text-center'>
        <h3 className='home-common-title'>Certifications</h3>
      </div>
      <div className='certification'>
        <Container>
          <Row>
            <div className='about-us-divvvv'>
              <div className='white-bggg'>
                <Row>
                  <Col md={5} sm={5} xs={5}>
                    <img src={process.env.PUBLIC_URL + '/assets/Image/About-us/bis.jpg'} className='mark-img' />
                  </Col>
                  <Col md={7} sm={7} xs={7}>
                    <p className='isi-txttt'>BIS Certified</p>
                  </Col>
                </Row>
                <Row className='mt-4'>
                  <Col md={5} sm={5} xs={5}>
                    <img src={process.env.PUBLIC_URL + '/assets/Image/About-us/fssai.webp'} className='mark-img' />
                  </Col>
                  <Col md={7} sm={7} xs={7}>
                    <p className='isi-txttt'>FSSAI</p>
                  </Col>
                </Row>
                <Row className='mt-4'>
                  <Col md={5} sm={5} xs={5}>
                    <img src={process.env.PUBLIC_URL + '/assets/Image/About-us/iso.png'} className='mark-img' />
                  </Col>
                  <Col md={7} sm={7} xs={7} >
                    <p className='isi-txttt'>ISO 22000:2018 Certified</p>
                  </Col>
                </Row>
              </div>
              <div className='cer-main-img'>
                <img src={process.env.PUBLIC_URL + '/assets/Image/About-us/girl.png'} className='girl-img' />
              </div>
            </div>            
          </Row>
        </Container>
      </div>
    </section>
  )
}

export default Certification
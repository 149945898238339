import React from 'react'
import './BlogBanner.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'


const BlogBanner = () => {
  return (
    <section className='blog-banner-details'>
      <div className='main-banner-class'>
        <img src={process.env.PUBLIC_URL + '/assets/Image/About-us/about-us.png'} className='blog-details-banner' />
        <Link to='/blog'><button type='button' className='back-btn'><FontAwesomeIcon icon="fa-solid fa-arrow-left-long" className="me-1" /> Back</button></Link>
      </div>
    </section>
  )
}

export default BlogBanner
import React, { useEffect } from 'react';
import './Gallery.css';
import { Col, Container, Row } from 'react-bootstrap';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import LazyLoad from 'react-lazyload';

const Gallery = () => {

    useEffect(() => {
        const handleScroll = () => {
            const videos = document.querySelectorAll('.video-works');
            videos.forEach(video => video.pause());
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <section className='gallery'>
            <Container>
                <div className='text-center mb-4'>
                    <h3 className='home-common-title'>Gallery</h3>
                    <p className='sub-txt mt-4 text-center'>
                        Discover artistry in every stroke, explore our captivating gallery.
                    </p>
                </div>
                <Row>
                    <Col lg={4} md={6} sm={12} xs={12} className='mt-4'>
                        <LazyLoad className='text-center'>
                            <video className='video-works' loop muted controls>
                                <source src={process.env.PUBLIC_URL + '/assets/Videos/t1.mp4'}></source>
                            </video>
                        </LazyLoad>
                    </Col>
                    <Col lg={4} md={6} sm={12} xs={12} className='mt-4'>
                        <LazyLoad className='text-center'>
                            <video className='video-works' loop muted controls>
                                <source src={process.env.PUBLIC_URL + '/assets/Videos/t2.mp4'}></source>
                            </video>
                        </LazyLoad>
                    </Col>
                    <Col lg={4} md={6} sm={12} xs={12} className='mt-4'>
                        <LazyLoad className='text-center'>
                            <video className='video-works' loop muted controls>
                                <source src={process.env.PUBLIC_URL + '/assets/Videos/t3.mp4'}></source>
                            </video>
                        </LazyLoad>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className='mt-4'>
                        <LazyLoad className='text-center'>
                            <video className='video-works' loop muted controls>
                                <source src={process.env.PUBLIC_URL + '/assets/Videos/t4.mp4'}></source>
                            </video>
                        </LazyLoad>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className='mt-4'>
                        <LazyLoad className='text-center'>
                            <video className='video-works' loop muted controls>
                                <source src={process.env.PUBLIC_URL + '/assets/Videos/t5.mp4'}></source>
                            </video>
                        </LazyLoad>
                    </Col>
                    <Col lg={4} md={6} sm={12} xs={12} className='mt-4'>
                        <LazyLoad className='text-center'>
                            <video className='video-works' loop muted controls>
                                <source src={process.env.PUBLIC_URL + '/assets/Videos/t6.mp4'}></source>
                            </video>
                        </LazyLoad>
                    </Col>
                    <Col lg={4} md={6} sm={12} xs={12} className='mt-4'>
                        <LazyLoad className='text-center'>
                            <video className='video-works' loop muted controls>
                                <source src={process.env.PUBLIC_URL + '/assets/Videos/t7.mp4'}></source>
                            </video>
                        </LazyLoad>
                    </Col>
                    <Col lg={4} md={6} sm={12} xs={12} className='mt-4'>
                        <LazyLoad className='text-center'>
                            <video className='video-works' loop muted controls>
                                <source src={process.env.PUBLIC_URL + '/assets/Videos/t8.mp4'}></source>
                            </video>
                        </LazyLoad>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className='mt-4'>
                        <LazyLoad className='text-center'>
                            <video className='video-works' loop muted controls>
                                <source src={process.env.PUBLIC_URL + '/assets/Videos/t9.mp4'}></source>
                            </video>
                        </LazyLoad>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className='mt-4'>
                        <LazyLoad className='text-center'>
                            <video className='video-works' loop muted controls>
                                <source src={process.env.PUBLIC_URL + '/assets/Videos/t10.mp4'}></source>
                            </video>
                        </LazyLoad>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className='mt-4'>
                        <LazyLoad className='text-center'>
                            <video className='video-works' loop muted controls>
                                <source src={process.env.PUBLIC_URL + '/assets/Videos/t11.mp4'}></source>
                            </video>
                        </LazyLoad>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className='mt-4'>
                        <LazyLoad className='text-center'>
                            <video className='video-works' loop muted controls>
                                <source src={process.env.PUBLIC_URL + '/assets/Videos/t12.mp4'}></source>
                            </video>
                        </LazyLoad>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Gallery;

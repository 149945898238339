import React from 'react'
import './AboutBanner.css'

const AboutBanner = () => {
  return (
    <section className='about-banner'>
      <img src={process.env.PUBLIC_URL +'/assets/Image/About-us/about.png'} className='about-banner-img'/>
    </section>
  )
}

export default AboutBanner
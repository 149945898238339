import React from 'react'
import BlogBanner from './BlogBanner/BlogBanner'
import BlogdetailsText from './BlogdetailsText/BlogdetailsText'

const BlogDetails = () => {
    return (
        <>
            <BlogBanner />
            <BlogdetailsText />
        </>
    )
}

export default BlogDetails
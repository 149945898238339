import React from 'react'
import './Goodness.css'
import { Container } from 'react-bootstrap'
import Countersss from '../../Services/Countersss/Countersss'
function Goodness() {
  return (
    <section className='gooodness_section'>
      <Container>
        <div className='mb-4'>
          <h3 className='home-common-title mb-4'><span className='start-txttt'>B</span>uilding a Trust with Goodness</h3>
          <Countersss />
        </div>
      </Container>
    </section>
  )
}

export default Goodness
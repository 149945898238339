import React from 'react'
import AboutBanner from './AboutBanner/AboutBanner'
import AboutText from './AboutText/AboutText'
import Certification from './Certification/Certification'
import Objectives from './Objectives/Objectives'

const AboutUs = () => {
  return (
    <>
          <AboutBanner />
          <AboutText />
          <Certification />
          <Objectives />
    </>
  )
}

export default AboutUs
import React from 'react'
import './WaterComposition.css'
import { Col, Container, Row } from 'react-bootstrap'

const WaterComposition = () => {
    return (
        <>
            <Container>
                <div className='mt-5 mb-4'>
                    <h3 className='home-common-title'><span className='start-txttt'>W</span>ater Composition</h3>
                    <p className='sub-txtttt-spring'>What's inside?</p>
                </div>
            </Container>
            <section className='waterrrrr'>
                <div className='water-composition'>
                    <Container>
                        <Row>
                            <Col lg={12} xs={12}>
                                <img src={process.env.PUBLIC_URL + '/assets/Image/Homepage/Water-Composition/without_hover.png'} className='water-compo' />
                            </Col>
                        </Row>
                        <Col lg={12} xs={12}>
                            <img src={process.env.PUBLIC_URL + '/assets/Image/Homepage/Water-Composition/with_hover.png'} className='water-compo-with-hover' />
                        </Col>
                    </Container>
                </div>
            </section>
        </>


    )
}

export default WaterComposition
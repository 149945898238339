import React from 'react'

import './Services.css'
import Countersss from './Countersss/Countersss'
import Purity from './Purity/Purity'
import WhyChoose from './WhyChoose/WhyChoose'
const Services = () => {
  
  return (
    <>
    <WhyChoose/>
    <Countersss/>
    <Purity/>
    </>
  )
}

export default Services
import React from "react";
import "./Footer.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { NavLink, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Row } from "react-bootstrap";

const Footer = () => {
  return (
    <>
      <section className="footer">
        <Container>
          <div className='row'>
            <div className='col-lg-3 col-md-12 col-sm-12 '>
              <Link as={Link} to="/"><img className='footerlogo' src={process.env.PUBLIC_URL + '/assets/Image/Logo/splasher-logo.png'} alt="Logo" /></Link>
            </div>
            <div className="col-lg-5 col-md-7 col-sm-12  ">
              <div className="row">
                <div className="col-lg-4 col-md-5">
                  <h3 className="footer-head-title">Menu</h3>
                  <Nav
                    defaultActiveKey="/"
                  >
                    <Nav.Item>
                      <NavLink className="nav-link" as={Link} to="/">
                        Home
                      </NavLink>
                    </Nav.Item>
                    <Nav.Item>
                      <NavLink className="nav-link" as={Link} to="/about-us">
                        About Us
                      </NavLink>
                    </Nav.Item>
                    <Nav.Item>
                      <NavLink className="nav-link" as={Link} to="/contact-us">
                        Contact Us
                      </NavLink>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="col-lg-8 col-md-7">
                  <h3 className="footer-head-title">Contact Us</h3>
                  <div className="location-holder">
                    <div className="call-holder mt-2">
                      <div className="d-flex">
                        <FontAwesomeIcon icon="fa-solid fa-location-dot" className="call-icon me-3" />
                        <span className="footer-txttt">Gat No-35/36, Jategaon Khurd, Chakan-Shikrapur Road Highway, Tal-Shirur, Dist-Pune 412208 Maharashtra, India</span>
                      </div>
                    </div>
                    <div className="call-holder mt-3 ">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon="fa-solid fa-phone"
                          className="call-icon me-3"
                        />
                        <span className="me-2">+91 8999392963</span>
                      </div>
                    </div>
                    <div className="call-holder mt-3 ">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon="fa-solid fa-envelope"
                          className="call-icon me-3"
                        />
                        <span>splashergermany@gmail.com</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-5 col-sm-12" >
              <h3 className="footer-head-title">Newsletter</h3>
              <div className="address-holder">
                <InputGroup className="mb-5 mt-4">
                  <Form.Control
                    placeholder="Enter your email"
                    aria-label="Enter your email"
                    aria-describedby="basic-addon2"
                  />
                  <InputGroup.Text id="basic-addon2" className="subscribe-btnnnn">Subscribe</InputGroup.Text>
                </InputGroup>
                <div className="social-icon">
                  <img
                    className="sociallogo"
                    src={
                      process.env.PUBLIC_URL + "/assets/Image/Icon/facebook.png"
                    }
                    alt="sociallogo"
                  />
                  <img
                    className="sociallogo"
                    src={
                      process.env.PUBLIC_URL + "/assets/Image/Icon/instagram.png"
                    }
                    alt="sociallogo"
                  />
                  <img
                    className="sociallogo"
                    src={
                      process.env.PUBLIC_URL + "/assets/Image/Icon/twitter (1).png"
                    }
                    alt="sociallogo"
                  />
                  <img
                    className="sociallogo"
                    src={
                      process.env.PUBLIC_URL + "/assets/Image/Icon/linkedin.png"
                    }
                    alt="sociallogo"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="copyright-holder  text-center">
                <p>
                  Copyright ©2024 All Rights Reserved.{" "}
                  <Link to="https://profcymaglobal.com/" target="___blank">
                    Developed by Profcyma.
                  </Link>{" "}
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Footer;


import React from 'react'
import './Blogdetails.css'
import { Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const BlogdetailsText = () => {
    return (
        <section className='main-blog'>
            <Container >
                <Row>
                    <Col lg={12}>
                        <div className='main-img-blog'>
                            <img src={process.env.PUBLIC_URL + '/assets/Image/Blog/blog1.png'} className='blog-img' />
                            <div className='white-bggg d-flex'>
                                <div className='me-2 common-txt'><FontAwesomeIcon icon="fa-solid fa-calendar" className='me-2' />September 25, 2023</div>
                                <div className='common-txt'><FontAwesomeIcon icon="fa-solid fa-user" /> admin</div>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <h3 className='blog-common-title'><span className='blue-border'>Ho</span>w water is useful for our body & life</h3>
                            <div>
                                <h5 className='mt-5'>What Sets Top-Level Purified Bottled Water Apart?</h5>
                                <ol type='number'>
                                    <li>Advanced Filtration Techniques:</li>
                                    <p className='sub-txt'>Top-tier bottled water brands use state-of-the-art filtration processes, including reverse osmosis, distillation, and ultraviolet (UV) light treatment. These methods remove impurities such as bacteria, viruses, heavy metals, and chemical contaminants, ensuring the water is of the highest purity.</p>
                                    <li>Rigorous Quality Control:</li>
                                    <p>Leading brands adhere to stringent quality standards and undergo regular testing to meet or exceed regulatory requirements. This ensures consistency in taste and safety across all batches of bottled water.</p>
                                    <li>Enhanced Mineral Content:</li>
                                    <p>Many premium bottled water options are enriched with essential minerals like calcium, magnesium, and potassium, which are beneficial for health. These minerals not only improve the taste but also contribute to overall well-being.</p>
                                    <li>Improved Hydration:</li>
                                    <p>Purified water is free from unpleasant tastes and odors, making it more enjoyable to drink. This encourages better hydration habits, essential for maintaining energy levels, cognitive function, and overall health.</p>
                                    <li>Detoxification</li>
                                    <p>High-quality purified water aids in flushing out toxins from the body, supporting kidney function and promoting clearer skin.</p>
                                </ol>
                                <h5 className='mt-5'>Conclusion</h5>
                                <p className='sub-txt'>Top-level purified bottled water offers a blend of health benefits, convenience, and peace of mind. By choosing high-quality bottled water, you ensure that you and your loved ones stay hydrated with the purest, safest water available. Remember to stay informed about the brands you choose and consider the environmental impact of your consumption to make the best choice for your health and the planet.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className='mt-5'>
                <img src={process.env.PUBLIC_URL + '/assets/Image/Blog/blogdetails.png'} className='blog-img mt-md-5' />
            </div>
            <Container>
                <div className='mt-5'>
                    <h3 className='blog-common-title'>AMC Entertainment sparks calls for scrutiny</h3>
                    <p className='sub-txt mt-4'>labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Korem ipsum dolor sit amet. Jorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam</p>
                    <div className='mb-5'>
                        <p className='sub-txt mt-4'><img src={process.env.PUBLIC_URL + '/assets/Image/Blog/mark.png'} className='mark-img' /> sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam </p>
                        <p className='sub-txt mt-4'><img src={process.env.PUBLIC_URL + '/assets/Image/Blog/mark.png'} className='mark-img' /> Stet clita kasd gubergren, no sea takimata sanctus  </p>
                        <p className='sub-txt mt-4'><img src={process.env.PUBLIC_URL + '/assets/Image/Blog/mark.png'} className='mark-img' /> Gorem ipsum dolor sit amet, consetetur sadipscing elitr</p>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default BlogdetailsText
import React from "react";
import "./Purity.css";
import { Col, Container, Row } from "react-bootstrap";
import PurityCard from "../../CommonComponents/PurityCard/PurityCard";
function Purity() {
  const data = [
    {
      image: process.env.PUBLIC_URL + "/assets/Image/service/water.png",
      name: "Maximum Purity",
      text: "Cras nec metus velit. Quisque sit amet justo id sapien rhoncus pharetra sed quis mi.",
    },
    {
      image: process.env.PUBLIC_URL + "/assets/Image/service/glasswater.png",
      name: "5 Steps Filtration",
      text: "Cras nec metus velit. Quisque sit amet justo id sapien rhoncus pharetra sed quis mi.",
    },
  ];
  return (
    <section className="purity_section">
      <Container>
        <div className="purity_div_main">
            <Row>
                <Col xxl={3} xl={3} lg={4} md={12} >             
          <Row>

            {data.map((item, index) => (
              <Col xxl={12} xl={12} lg={12} md={6} sm={6} className="mb-3 my-auto">
                <PurityCard item={item}/>
              </Col>
            ))}
          </Row>
          </Col>
          <Col  xxl={9} xl={9} lg={8} md={12}>
          <div className="watrimgdiv">
          <img src={process.env.PUBLIC_URL + "/assets/Image/service/girlbottle.png"} className="watr_img" />

          </div>
          </Col>
            </Row>
        </div>
      </Container>
    </section>
  );
}

export default Purity;

import React from 'react'
import './AboutText.css'
import { Col, Container, Row } from 'react-bootstrap'

const AboutText = () => {
  return (
    <section className='about-txt'>
      <Container>
        <div className='text-center mb-4'>
          <h3 className='home-common-title'>About Us</h3>
          <p className='sub-txt-about'>Welcome to Spalsher The Pride Of Pune</p>
        </div>
        <Row>
          <Col lg={12}>
            <div>
              <p className='sub-txt'>India's premier packaged drinking water provider. Our commitment to quality, purity, and convenience sets us apart in the industry. With cutting-edge purification technology and stringent quality control measures, we ensure that every drop of water that reaches you is of the highest standard. From the pristine sources to your doorstep, our focus on excellence remains unwavering</p>
              <p className='sub-txt'>Our eco-friendly packaging reflects our dedication to sustainability, while our nationwide distribution network ensures timely delivery to every corner of the country. Whether you're at home, work, or on the go, trust to provide you with refreshing hydration whenever you need it. Join us in our mission to promote health and well-being through accessible, safe drinking water. Choose Us for purity, convenience, and peace of mind.</p>
            </div>
          </Col>
        </Row>
      </Container>

    </section>
  )
}

export default AboutText
import React, { useState, useEffect } from "react";
import "./Header.css";
import { Link, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);
const Header = () => {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(() => {
    return localStorage.getItem("activeItem") || "home";
  });

  useEffect(() => {
    const path = location.pathname.substr(1);
    updateActiveItem(path || "home");
  }, [location]);

  const updateActiveItem = (newActiveItem) => {
    localStorage.setItem("activeItem", newActiveItem);
    setActiveItem(newActiveItem);
  };
  const [activeLink, setActiveLink] = useState("home");
  useEffect(() => {
    const path = location.pathname.substring(1);
    setActiveLink(path || "home");
  }, [location.pathname]);
  const handleNavItemClick = (selectedLink) => {
    setActiveLink(selectedLink);
    const navbarToggle = document.querySelector(".navbar-toggler");
    if (navbarToggle && window.innerWidth <= 992) {
      navbarToggle.click();
    }
  };

  const [searchToggled, setIsToggled] = useState(false);
  const toggleClick = () => {
    setIsToggled(!searchToggled);
  };
  return (
    <>
      {/* start header section  */}
      <section className="second-header-sec">
        <Container>
          <Navbar expand="lg">
            <Navbar.Brand as={Link} to="/">
              <img className="headlogo" src={
                process.env.PUBLIC_URL + "/assets/Image/Logo/splasher-logo.png"} alt="Logo" />
            </Navbar.Brand>
            <div className="main-contact-class d-flex">
              <div>
                <img className="call-icon" src={
                  process.env.PUBLIC_URL + "/assets/Image/Icon/Icon material-call.png"} alt="Logo" />
                <div className="iconnnn-hover">
                  <img className="call-icon-hover" src={
                    process.env.PUBLIC_URL + "/assets/Image/Header/call.png"} alt="Logo" />
                </div>
              </div>
              <div className="second-divvvvv">
                <p className="place-txtttttt">To place order</p>
                <p className="phone-number">+91 8999392963</p>
              </div>
            </div>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <Nav.Link as={Link} to="/" active={activeItem === "home"} onClick={handleNavItemClick}  >
                  <span className="init-letter">H</span>ome
                </Nav.Link>
                <Nav.Link as={Link} to="/about-us" active={activeItem === "about-us"} onClick={handleNavItemClick} >
                  <span className="init-letter">A</span>bout Us
                </Nav.Link>
                {/* <Nav.Link as={Link} to="/team" active={activeItem === "team"} onClick={handleNavItemClick}  >
                  <span className="init-letter">T</span>eam
                </Nav.Link> */}
                {/* <Nav.Link as={Link} to="/service" active={activeItem === "service"} onClick={handleNavItemClick}>
                  <span className="init-letter">S</span>ervices
                </Nav.Link> */}
                <Nav.Link as={Link} to="/blog" active={activeItem === "blog"} onClick={handleNavItemClick} >
                  <span className="init-letter">B</span>log
                </Nav.Link>
                <Nav.Link as={Link} to="/gallery" active={activeItem === "gallery"} onClick={handleNavItemClick}>
                  <span className="init-letter">G</span>allery
                </Nav.Link>
                <Nav.Link as={Link} to="/contact-us" active={activeItem === "contact-us"} onClick={handleNavItemClick} >
                  <span className="init-letter">C</span>ontact Us
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </section>
      {/* end header section  */}
    </>
  );
};

export default Header;

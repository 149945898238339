import React from 'react'
import './FindUs.css'
import { Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import Form from 'react-bootstrap/Form';

const FindUs = () => {
  const locations = [
    {
      name: 'PUNE',
      branchname: 'S V MARKETING',
      address: 'Gat No- 35/36, Shikrapur-Chakan Road, Pune 412208',
      email: 'splashergermany@gmail.com',
      phone: '+91 7499160629'
    },
    {
      name: 'SATARA',
      branchname: 'Sarvadnya Enterprises',
      address: 'Gokul Nagar, Tal-Khandala, Dist-Satara',
      email: 'splashergermany@gmail.com',
      phone: '+91 8446392963'
    },
    {
      name: 'SOLAPUR',
      branchname: 'Shree Ganesh Agency',
      address: 'Main Road, ST Stand, Solapur',
      email: 'splashergermany@gmail.com',
      phone: '+91 7499160629'
    },
    {
      name: 'AHAMADNAGAR',
      branchname: 'Unik Distributor',
      address: 'Savedi Road, Near Datta Mandir, Ahamadnagar',
      email: 'splashergermany@gmail.com',
      phone: '+91 8446392963'
    },
    {
      name: 'PANVEL',
      branchname: 'Vrushabh Agency',
      address: 'Pali Road, Near Ganesh Vision, Panvel',
      email: 'splashergermany@gmail.com',
      phone: '+91 8999392963'
    },
  ];
  return (
    <section className='find-us'>
      <Container>
        <div className='text-center mb-4'>
          <h3 className='home-common-title'><span className='start-txttt'>F</span>ind us in your area</h3>
        </div>
        <Row className='mt-5'>
          <Col lg={4} md={12} sm={12} xs={12}>
            <div className='place-order-form'>
              <p className='.sub-txt place-order-txtt'>We will deliver water to your home. Just fill the delivery form below.</p>
              <Form>
                <Form.Group className="mb-3" controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter Your Name" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formAddress">
                  <Form.Label>Address</Form.Label>
                  <Form.Control type="text" placeholder="Enter your address" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formPhoneNumber">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control type="tel" placeholder="Enter your phone number" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formQuantity">
                  <Form.Label>Quantity</Form.Label>
                  <Form.Control type="number" placeholder="Enter quantity" />
                </Form.Group>
                <div className='text-center mt-4'>
                  <button type='button' className='common-btn'><span className='common-span'>Place Order</span></button>
                </div>
              </Form>
            </div>
          </Col>
          <Col lg={8} md={12} sm={12} xs={12}>
            <Row className='mt-lg-0 mt-md-5 mt-3'>
              {locations.map((location, index) => (
                <Col key={index} lg={4} md={6} sm={12} xs={12}>
                  <div className='white-bg-hover'>
                    <h3 className='location-head'>{location.name}</h3>
                    <p className='branchname'>{location.branchname}</p>
                    <p className='address'>{location.address}</p>
                    <span className='mail-add'>
                      <FontAwesomeIcon icon={faEnvelope} className='iconnnnn me-2' />
                      {location.email}
                    </span>
                    <p className='address'>
                      <FontAwesomeIcon icon={faPhone} className='iconnnnn me-2' />
                      {location.phone}
                    </p>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
      <div className='main-img-classsss text-end'>
        <img src={process.env.PUBLIC_URL + '/assets/Image/Contact-us/breadcrumb-man.png'} className='man-image' />
      </div>
    </section>
  )
}

export default FindUs
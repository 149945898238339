import React from 'react'
import'./WaterDetails.css'
import { Link } from 'react-router-dom'
function WaterDetails({data}) {
  return (
    <>
    <section className='waterdetails_section'>
        <div className='waterdetails_main'>
            <div className='textdiv_main'>
                      <h6 className='midtext systemtext'><span className='blue-border'>{data.startcaracter}</span>{data.subtitle}</h6>
                <div className='ptext_div'>
                          <p className='sub-txt'>{data.text1} </p>
                </div>
                <div className=' bottom_buttons'>
                    <div className='icnimg_div'>
                              <img src={data.image} className='icn_img'/>
                    </div>
                    <div>
                     <Link to="/serviceinner"> <button className='common-btn'><span className='common-span'>Read More</span></button></Link>
                    </div>
                </div>
            </div>
            <div className='waterdiv_img'>
                      <img src={data.image1} className='water_img'/>
            </div>
        </div>
    </section>
    
    </>
  )
}

export default WaterDetails
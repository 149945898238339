import React from 'react'
import './Countersss.css'
import { Col, Container, Row } from 'react-bootstrap'
import CountUp from 'react-countup';
function Countersss() {
    return (
        <section className='counters_section'>
            <Container>
                <div>
                    <Row className="row-cols-xl-5 row-cols-md-5 row-cols-sm-2 row-cols-1 p-2">
                        <Col className='leftborder mb-2'>
                            <div className='cont_div '>
                                <div className='conuts_main_div'>
                                    <CountUp duration={15} end={500000} className='' />K
                                </div>
                                <p className='sub-txt cunttxt text-center mb-0'>Water Bottles Delivered</p>

                            </div>
                        </Col>
                        <Col className='leftborder mb-2'>
                            <div className='cont_div '>
                                <div className='conuts_main_div'>
                                    <CountUp delay={2} end={15} className='' />+
                                </div>
                                <p className='sub-txt cunttxt text-center mb-0'>Years of Experienced</p>

                            </div>
                        </Col>
                        <Col className='leftborder mb-2'>
                            <div className='cont_div '>
                                <div className='conuts_main_div'>
                                    <CountUp delay={2} end={60} className='' />
                                </div>
                                <p className='sub-txt cunttxt text-center mb-0'>Professional Team Members</p>

                            </div>
                        </Col>
                        <Col className='leftborder mb-2'>
                            <div className='cont_div '>
                                <div className='conuts_main_div'>
                                    <CountUp delay={2} end={89} className='' />
                                </div>
                                <p className='sub-txt cunttxt text-center mb-0'>Currently Serving Areas in City</p>

                            </div>
                        </Col>
                        <Col className='mb-2'>
                            <div className='cont_div'>
                                <div className='conuts_main_div'>
                                    <CountUp delay={2} end={1140} className='' />K
                                </div>
                                <p className='sub-txt cunttxt text-center mb-0'>Customers Happy with Us</p>

                            </div>
                        </Col>

                    </Row>
                </div>
            </Container>
        </section>
    )
}

export default Countersss
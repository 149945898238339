import React from 'react'
import './RecentNews.css'
import { Card, Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';

const newsItems = [
  {
    id: 1,
    imgSrc: '/assets/Image/Blog/blog1.png',
    title: 'Produce top level purified bottled water',
    date: 'September 25, 2023'
  },
  {
    id: 2,
    imgSrc: '/assets/Image/Blog/blog2.png',
    title: 'How water useful for our body & life',
    date: 'September 25, 2023'
  },
  {
    id: 3,
    imgSrc: '/assets/Image/Blog/blog3.png',
    title: 'We are promised to deliver bottled water in-time',
    date: 'September 25, 2023'
  }
];

const RecentNews = () => {
  return (
    <section className='recent-news'>
      <div>
        <h3 className='blog-common-title'>Recent Blogs</h3>
        {newsItems.map((item) => (
          <Link to='/blog-details'>
            <div className='recent-news-blog-box' key={item.id}>
              <Row>
                <Col md={3} sm={3} xs={3}>
                  <img src={process.env.PUBLIC_URL + item.imgSrc} className='recent-img' alt={item.title} />
                </Col>
                <Col md={9} sm={9} xs={9}>
                  <h4 className='recent-title'>{item.title}</h4>
                  <span className='common-txt'><FontAwesomeIcon icon="fa-solid fa-calendar-days" className='me-2 common-txt' /> {item.date}</span>
                </Col>
              </Row>
            </div>
          </Link>
          
        ))}
      </div>
      <div className='blog-card'>
        <Card>
          <Card.Img variant="top" src={process.env.PUBLIC_URL + '/assets/Image/Blog/blog.png'} className='blog-imgg-call' />
          <Card.Body>
            <Row>
              <Col md={6} sm={6} xs={6}>
                <div className='sub-main-rececc'>
                  <div className='font-icon-main'>
                    <FontAwesomeIcon icon="fa-solid fa-phone" className='font-icon' />
                  </div>
                  <span className='sub-txt double-txtt'>Call Us</span>
                  <p className='common-txt'>+91 9876543210</p>
                </div>
              </Col>
              <Col md={6} sm={6} xs={6}>
                <div className='sub-main-rececc'>
                  <div className='font-icon-main'>
                    <FontAwesomeIcon icon="fa-solid fa-envelope" className='font-icon' />
                  </div>
                  <span className='sub-txt double-txtt'>Our Mail</span>
                  <p className='common-txt'>info@example.com</p>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </section>
  )
}

export default RecentNews

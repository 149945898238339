import React from 'react'
import HomeBanner from './HomeBanner/HomeBanner'
import HomeAboutUs from './HomeAboutUs/HomeAboutUs'
import KeyParameter from './KeyParameter/KeyParameter'
import WaterComposition from './WaterComposition/WaterComposition'
import BottleWeDeliver from './BottleWeDeliver/BottleWeDeliver'
import HowItWorks from './HowItWorks/HowItWorks'
import GalleryVideo from './GalleryVideo/GalleryVideo'
import Reviews from './Reviews/Reviews'
import Goodness from './Goodness/Goodness'
import LeadingTowards from './LeadingTowrds/LeadingTowards'
import ServicingAcross from './ServicingAcross/ServicingAcross'



const HomePage = () => {
    return (
        <>
            <HomeBanner />
            <ServicingAcross />
            <HomeAboutUs />
            <HowItWorks />
            <GalleryVideo />
            <Reviews />
            <KeyParameter />
            <BottleWeDeliver />
            <Goodness />
            <LeadingTowards />
            <WaterComposition />
        </>
    )
}

export default HomePage
import React from 'react'
import './Team.css'
import { Col, Container, Row } from 'react-bootstrap'

const teamMembers = [
    {
        id: 1,
        imgSrc: '/assets/Image/Team/t1.png',
        name: 'John Due',
        position: 'Chairman',
        socialIcons: [
            '/assets/Image/Gallery/facebook.png',
            '/assets/Image/Gallery/insta.png',
            '/assets/Image/Gallery/twitter.png'
        ]
    },
    {
        id: 2,
        imgSrc: '/assets/Image/Team/t2.png',
        name: 'Sunny Khan',
        position: 'Chairman',
        socialIcons: [
            '/assets/Image/Gallery/facebook.png',
            '/assets/Image/Gallery/insta.png',
            '/assets/Image/Gallery/twitter.png'
        ]
    },
    {
        id: 3,
        imgSrc: '/assets/Image/Team/t3.png',
        name: 'shubham Kumar',
        position: 'CEO',
        socialIcons: [
            '/assets/Image/Gallery/facebook.png',
            '/assets/Image/Gallery/insta.png',
            '/assets/Image/Gallery/twitter.png'
        ]
    },
    {
        id: 4,
        imgSrc: '/assets/Image/Team/t4.png',
        name: 'Rocky Jaha',
        position: 'Director',
        socialIcons: [
            '/assets/Image/Gallery/facebook.png',
            '/assets/Image/Gallery/insta.png',
            '/assets/Image/Gallery/twitter.png'
        ]
    }
];

const Team = () => {
    return (
        <section className='team'>
            <Container>
                <div className='text-center mb-4'>
                    <h3 className='home-common-title'>Leadership Team</h3>
                    <p className='sub-txt-team mt-4'>
                        We do our best to deliver natural water to your home or office.
                        <br />
                        We have been working together for many years and are getting better every day.
                    </p>
                </div>
                <Row>
                    {teamMembers.map(member => (
                        <Col lg={3} md={4} sm={6} xs={12} key={member.id} className='mt-5'>
                            <div className='main-img-team'>
                                <img src={process.env.PUBLIC_URL + member.imgSrc} className='team-img' alt={member.name} />
                                <div className='overlay text-center'>
                                    <div className='center-pos'>
                                        <h6 className='team-name'>{member.name}</h6>
                                        <span className='common-txt-posss'>{member.position}</span>
                                        <div className='d-flex justify-content-center'>
                                            {member.socialIcons.map((icon, index) => (
                                                <img key={index} src={process.env.PUBLIC_URL + icon} className='social-icon-img' alt='social-icon' />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    )
}

export default Team

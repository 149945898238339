import React from 'react'
import './Objectives.css'
import { Col, Container, Row } from 'react-bootstrap'

const objectivesData = [
  {
    title: 'Vision',
    text: [
      'To reaffirm and promote all people\'s right to safe drinking water. With a focus on developing partnerships with rural communities throughout Canada and around the world, we intend to effect change at the municipal, provincial, and federal levels as well as within civil society and industries involved in the protection and production of public water supplies.',
      'We will deliver high quality, hands-on educational programs to thousands of classrooms in order to educate students, the leaders of tomorrow, about drinking water quality issues and solutions.'
    ],
    imgSrc: '/assets/Image/About-us/vision.png'
  },
  {
    title: 'Mission',
    text: [
      'We will educate the leaders of today and tomorrow about drinking water quality issues to realize our goal of safe drinking water being available to every Canadian.',
      'Our mission is to provide high quality, healthy and affordable drinking water to our customers. We bring natural bottled mineral water, rich in minerals that benefit the body and increase vitality. We bring trust and mutual respect to all our relationships by continually improving the quality and process of our product. We focus on natural well being, and encourage a life style that is environmentally friendly.'
    ],
    imgSrc: '/assets/Image/About-us/mission.png'
  },
  {
    title: 'Values',
    text: [
      'We believe in treating our customers with respect. We always thrive to cater to their needs.We believe in change and growth. We invest in creativity and innovation.',
      'We integrate honesty, integrity and business ethics into all aspects of our business functioning.Your trust means a lot to us. We work hard in maintaining high standards of production and will not compromise on our promise.'
    ],
    imgSrc: '/assets/Image/About-us/values.png'
  }
]

const Objectives = () => {
  return (
    <section className='objectives'>
      <Container>
        <div className='text-center mb-4'>
          <h3 className='home-common-title'>Objectives</h3>
        </div>
        {objectivesData.map((obj, index) => (
          <div key={index} className='objective-main'>
            <Row>
              <Col md={5} sm={5} xs={12}>
                <h3 className='objectives-title'>{obj.title}</h3>
                {obj.text.map((paragraph, i) => (
                  <p key={i} className='sub-txt'>{paragraph}</p>
                ))}
              </Col>
              <Col md={1} sm={1} xs={12}>

              </Col>
              <Col md={6} sm={6} xs={12}>
                <div className='obj-main-img'>
                  <img src={process.env.PUBLIC_URL + obj.imgSrc} className='obj-img' alt={obj.title} />
                </div>
              </Col>
            </Row>
          </div>
        ))}
      </Container>
    </section>
  )
}

export default Objectives

import React from 'react'
import './HomeAboutUs.css'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const HomeAboutUs = () => {
    return (
        <section className='home-about-us'>
            <Container>
                <div className='mb-4'>
                    <h3 className='home-common-title'><span className='start-txttt'>A</span>bout Us</h3>
                </div>
                <Row>
                    <Col lg={4} md={4} sm={6} xs={12}>
                        <p className='sub-txtttt-spring'>Spring water to <br/> homes and businesses</p>
                    </Col>
                    <Col lg={8} md={8} sm={6} xs={12}>
                        <div className='main-light-bgggggg'>
                            <div className='light-blue-bggg'>
                                <Row>
                                    <Col lg={4} md={6} sm={6} xs={6}>
                                    </Col>
                                    <Col lg={8} md={6} sm={6} xs={6}>
                                        <h4 className='isi-txttt'>ISI & ISO</h4>
                                        <p className='certi-txttt'>Certified Company</p>
                                    </Col>
                                </Row>
                                <div className='white-bggg'>
                                    <div className='d-flex'>
                                        <div className='blkkkk-border '>
                                            <img src={process.env.PUBLIC_URL + '/assets/Image/About-us/fssai.webp'} className='iso-mark-png me-2 mt-2' />
                                        </div>
                                        <div>
                                            <img src={process.env.PUBLIC_URL + '/assets/Image/Icon/iso.png'} className='iso-mark-png ms-2' />
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xxl={12} className='mt-3'>
                        <h4 className='sub-txt'>Welcome to Splasher The Pride Of Pune</h4>
                        <p className='sub-txt'>India's premier packaged drinking water provider. Our commitment to quality, purity, and convenience sets us apart in the industry. With cutting-edge purification technology and stringent quality control measures, we ensure that every drop of water that reaches you is of the highest standard. From the pristine sources to your doorstep, our focus on excellence remains unwavering.</p>
                        <p className='sub-txt'>Our eco-friendly packaging reflects our dedication to sustainability, while our nationwide distribution network ensures timely delivery to every corner of the country. Whether you're at home, work, or on the go, trust to provide you with refreshing hydration whenever you need it. Join us in our mission to promote health and well-being through accessible, safe drinking water. Choose Us for purity, convenience, and peace of mind.</p>
                    </Col>
                    <Col lg={10} md={9} sm={6} xs={6}>
                        <div className='blue-border mt-4'></div>
                    </Col>
                    <Col lg={2} md={3} sm={6} xs={6} className='text-end'>
                        <Link to='/about-us'><button className="common-btn" type="button"><span className='common-span'>Read More</span></button></Link>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default HomeAboutUs
import React from 'react';
import './GetInTouch.css';
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const GetInTouch = () => {
  const contactInfo = [
    {
      icon: "fa-solid fa-location-dot",
      title: "Address",
      description: "Gat No-35/36, Jategaon Khurd, Chakan-Shikrapur Road Highway, Tal-Shirur, Dist-Pune 412208 Maharashtra, India"
    },
    {
      icon: "fa-solid fa-phone",
      title: "Phone",
      description: "Customer Care-7528010101"
    },
    {
      icon: "fa-solid fa-envelope",
      title: "Email",
      description: "Drop us a mail we will answer asap. (splashergermany@gmail.com)"
    },
    {
      icon: "fa-solid fa-clock",
      title: "Working Hours",
      description: "Our flexible working time to service you."
    }
  ];

  return (
    <section className='get-in-touch'>
      <Container>
        <div className='text-center mb-4'>
          <h3 className='home-common-title'><span className='start-txttt'>G</span>et In Touch</h3>
        </div>
        <Row className='mt-5'>
          {contactInfo.map((info, index) => (
            <Col key={index} lg={3} md={6} sm={12} xs={12}>
              <div className='white-bgggg'>
                <div className='font-ggfsjz'>
                  <div className='font-icon-main'>
                    <FontAwesomeIcon icon={info.icon} className='font-icon' />
                  </div>
                </div>
                <div className='text-center mt-2'>
                  <span className='sub-txt double-txtt'>{info.title}</span>
                  <p className='sub-txt sub-double-txtt'>{info.description}</p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default GetInTouch;

import React from 'react'
import './LeadingTowards.css'
import { Col, Container, Row } from 'react-bootstrap'
function LeadingTowards() {
    return (
        <section className='leading_section'>
            <Container>
                <div className='mb-4'>
                    <h3 className='home-common-title mb-4'><span className='start-txttt'>L</span>eading towards Change</h3>
                    <div>
                        <Row className='g-0'>
                            <Col lg={6} md={12} sm={12} xs={12}>
                                <div className='light-blue-bgggg'>
                                    <div className='common-light'>
                                        <div className='bootletxxtdiv'>
                                            <h2 className='btltexts'>Bottles for Change <br /> aiming to create <br /> awareness that</h2>
                                        </div>
                                        <Row>
                                            <Col lg={4}>
                                                <div className='main-border'>
                                                    <div className='white-bg'>
                                                        <p>Used plastic is not waste</p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div className='main-border'>
                                                    <div className='white-bg'>
                                                        <p>Used plastic is not waste</p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div className='main-border'>
                                                    <div className='white-bg'>
                                                        <p>Used plastic is not waste</p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>                              
                            </Col>                            
                            <Col lg={6} md={12} sm={12} xs={12}>
                                <div className='imgblue_div'>
                                <div className='blue-bg'></div>                               
                                <div className='bootle_img_div'>
                                    <img src={process.env.PUBLIC_URL + "/assets/Image/Homepage/KeyParameter/boxbottle.png"} className='bootles_img' />
                                </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default LeadingTowards
import React from 'react'
import './MainBlog.css'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';

const blogPosts = [
    {
        "id": 1,
        "imgSrc": "/assets/Image/Blog/blog1.png",
        "date": "September 25, 2023",
        "author": "admin",
        "title": "How water is useful for our body & life",
        "description": "In today's health-conscious world, the quality of the water we consume is more important than ever. With growing concerns about tap water contaminants and the need for convenient hydration, top-level purified bottled water has become a staple for many households and individuals. Here’s why opting for the best in bottled water can significantly enhance your health and lifestyle."
    },
    // {
    //     id: 2,
    //     imgSrc: '/assets/Image/Blog/blog2.png',
    //     date: 'September 25, 2023',
    //     author: 'admin',
    //     title: 'Produce top level purified bottled water',
    //     description: 'Sed viverra ullamcorper interdum. Cras eu convallis libero. Nulla venenatis vitae odio sed dictum. Mauris volutpat dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.....'
    // },
    // {
    //     id: 3,
    //     imgSrc: '/assets/Image/Blog/blog3.png',
    //     date: 'September 25, 2023',
    //     author: 'admin',
    //     title: 'We are promised to deliver bottled water in-time',
    //     description: 'Donec eu sem dolor. Integer quis lacus faucibus, dapibus felis eu, condimentum urna. Suspendisse commodo ornare velit non vestibulum. Pgrem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et.....'
    // }
];

const MainBlog = () => {
    return (
        <section className='main-blog'>
            <Row>
                {blogPosts.map((post) => (
                    <Link to='/blog-details'>
                        <Col lg={12} key={post.id} className='mt-5'>
                            <div className='main-img-blog'>
                                <img src={process.env.PUBLIC_URL + post.imgSrc} className='blog-img' alt={post.title} />
                                <div className='white-bggg d-flex'>
                                    <div className='me-2 common-txt'>
                                        <FontAwesomeIcon icon="fa-solid fa-calendar" className='me-2' />{post.date}
                                    </div>
                                    <div className='common-txt'>
                                        <FontAwesomeIcon icon="fa-solid fa-user" /> {post.author}
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <h3 className='blog-common-title'>{post.title}</h3>
                                <p className='sub-txt'>{post.description}</p>
                                <Link to='/blog-details'><button className='common-btn' type='button'><span className='common-span'>Read More</span></button></Link>
                            </div>
                        </Col>
                    </Link>                  
                ))}
            </Row>
        </section>
    )
}

export default MainBlog

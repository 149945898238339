import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import LazyLoad from 'react-lazyload';
import './Reviews.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Reviews = () => {
    const [swiperInstance, setSwiperInstance] = useState(null);

    const reviews = [
        {
            name: "Mr. Abhijeet Kanhade",
            position: "Manager, (Max care)",
            videoSrc: '/assets/Videos/maxcare.mp4'
        },
        {
            name: "Mr. Nilesh Upadhye",
            position: "Canteen Manager, (YCM )",
            videoSrc: '/assets/Videos/intro.mp4'
        },
        {
            name: "",
            position: "Owner (KGN Enterprises)",
            videoSrc: '/assets/Videos/KGN.mp4'
        },
        {
            name: "Mr. Aggrwal",
            position: "CTO, Tech Solutions",
            videoSrc: '/assets/Videos/aggrawal bit.mp4'
        },
        {
            name: "Mr. Rakesh Gaikwad",
            position: "(Owner), CNS Enterprises",
            videoSrc: '/assets/Videos/rakesh.mp4'
        }
    ];

    useEffect(() => {
        if (swiperInstance) {
            const handleSlideChange = () => {
                const videos = document.querySelectorAll('.video-review');
                videos.forEach(video => video.pause());
            };

            swiperInstance.on('slideChange', handleSlideChange);
            swiperInstance.on('transitionEnd', handleSlideChange);

            return () => {
                if (swiperInstance) {
                    swiperInstance.off('slideChange', handleSlideChange);
                    swiperInstance.off('transitionEnd', handleSlideChange);
                }
            };
        }
    }, [swiperInstance]);

    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }
    };

    return (
        <section className='review-home'>
            <Container>
                <div className="mb-4">
                    <h3 className="home-common-title">
                        <span className="start-txttt">R</span>eviews
                    </h3>
                    <p className="sub-txtttt-spring">
                        Keeping us motivated and helping to improve
                    </p>
                </div>
                <Swiper
                    spaceBetween={30}
                    slidesPerView={1}
                    pagination={{
                        dynamicBullets: true,
                    }}
                    autoplay={{ delay: 3000 }}
                    onSwiper={(swiper) => setSwiperInstance(swiper)}
                    className="mySwiper"
                >
                    {reviews.map((review, index) => (
                        <SwiperSlide key={index}>
                            <Row className='g-0'>
                                <Col lg={8} sm={8} xs={12}>
                                    <div className='video-div'>
                                        <LazyLoad>
                                            <video className='video-review' playsInline controls loop>
                                                <source src={process.env.PUBLIC_URL + review.videoSrc}></source>
                                            </video>
                                        </LazyLoad>
                                    </div>
                                </Col>
                                <Col lg={4} sm={4} xs={12}>
                                    <div className='blue-bggggg'>
                                        <div className='text-center'>
                                            <FontAwesomeIcon icon="fa-solid fa-quote-left" className='double-comma' />
                                            <h4 className='blog-common-title mt-md-5 mt-2'>{review.name}</h4>
                                            <p className='sub-txt text-center mb-md-0 mb-5'>{review.position}</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="silder-btn">
                    <div
                        className="back-btn"
                        onClick={() => handleMainSliderClick("prev")}
                    >
                        <div className='blue-border'>
                            <FontAwesomeIcon icon="fa-solid fa-angle-left" className='icon-btnnn' />
                        </div>
                    </div>
                    <div
                        className="next-btn"
                        onClick={() => handleMainSliderClick("next")}
                    >
                        <FontAwesomeIcon icon="fa-solid fa-angle-right" className='icon-btnnn' />
                        
                    </div>
                </div>
            </Container>
        </section>
    );
}

export default Reviews;

import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import './GalleryVideo.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const videoSources = [
    '/assets/Videos/t1.mp4',
    '/assets/Videos/t2.mp4',
    '/assets/Videos/t3.mp4',
    '/assets/Videos/t4.mp4',
    '/assets/Videos/t5.mp4',
    '/assets/Videos/t6.mp4',
    '/assets/Videos/t7.mp4',
    '/assets/Videos/t8.mp4',
    '/assets/Videos/t9.mp4',
    '/assets/Videos/t10.mp4',
    '/assets/Videos/t11.mp4',
    '/assets/Videos/t12.mp4',
];

const GalleryVideo = () => {
    const [swiperInstance, setSwiperInstance] = useState(null);

    useEffect(() => {
        if (swiperInstance) {
            const handleSlideChange = () => {
                const videos = document.querySelectorAll('.video-works');
                videos.forEach(video => video.pause());
            };

            swiperInstance.on('slideChange', handleSlideChange);
            swiperInstance.on('transitionEnd', handleSlideChange);

            return () => {
                if (swiperInstance) {
                    swiperInstance.off('slideChange', handleSlideChange);
                    swiperInstance.off('transitionEnd', handleSlideChange);
                }
            };
        }
    }, [swiperInstance]);

    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }
    };

    return (
        <section className='galleryvideo'>
            <Container>
                <Swiper
                    spaceBetween={30}
                    pagination={{
                        dynamicBullets: true,
                    }}
                    autoplay={{
                        delay: 10000
                    }}
                    modules={[Autoplay, Pagination]}
                    onSwiper={(swiper) => setSwiperInstance(swiper)}
                    className="mySwiper"
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                        },
                        420: {
                            slidesPerView: 1,
                        },
                        486: {
                            slidesPerView: 2,
                        },
                        768: {
                            slidesPerView: 2,
                        },
                        992: {
                            slidesPerView: 3,
                        },
                        1200: {
                            slidesPerView: 3,
                        },
                    }}
                >
                    {videoSources.map((src, index) => (
                        <SwiperSlide key={index}>
                            <LazyLoad className='text-center'>
                                <video className='video-works' loop controls>
                                    <source src={process.env.PUBLIC_URL + src} />
                                </video>
                            </LazyLoad>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="silder-btn">
                    <div
                        className="back-btn"
                        onClick={() => handleMainSliderClick("prev")}
                    >
                        <div className='blue-border'>
                            <FontAwesomeIcon icon="fa-solid fa-angle-left" className='icon-btnnn' />
                        </div>
                    </div>
                    <div
                        className="next-btn"
                        onClick={() => handleMainSliderClick("next")}
                    >
                        <FontAwesomeIcon icon="fa-solid fa-angle-right" className='icon-btnnn' />
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default GalleryVideo;

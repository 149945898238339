import React from "react";
import "./App.css";
import "./index.css";
import { useLocation } from 'react-router-dom';
import { Route, Routes } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import ScrollToTop from "./components/ScrollTotop/ScrollTotop";
import HomePage from "./components/HomePage/HomePage";
import Blog from "./components/Blog/Blog";
import Team from "./components/Team/Team";
import Services from "./components/Services/Services";
import Gallery from "./components/Gallery/Gallery";
import AboutUs from "./components/AboutUs/AboutUs";
import BlogDetails from "./components/BlogDetails/BlogDetails";
import ContactUs from "./components/ContactUs/ContactUs";
import ServiceInner from "./components/Services/ServiceInner/ServiceInner";



function App() {
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';
  return (
    <>
      <ScrollToTop />
      <div>
        {!isLoginPage && <Header />}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/team" element={<Team />} />
          <Route path="/service" element={<Services />} />
          <Route path="/serviceinner" element={<ServiceInner />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog-details" element={<BlogDetails />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
        {!isLoginPage && <Footer />}
      </div>

    </>
  );
}

export default App;

import React from 'react'
import './BottleWeDeliver.css'
import { Col, Container, Row } from 'react-bootstrap'


const BottleWeDeliver = () => {

    const products = [
        {
            imgSrc: "/assets/Image/Homepage/Bottle-Deliver/250 ML.png",
            name: "250 ML Water",
            quantity: "1 X 48 Bottles",
            price: "₹ 180.00/-"
        },
        {
            imgSrc: "/assets/Image/Homepage/Bottle-Deliver/Half-liter-bottle.png",
            name: "1/2 Litter Water",
            quantity: "1 X 24 Bottles",
            price: "₹ 115.00/-"
        },
        {
            imgSrc: "/assets/Image/Homepage/Bottle-Deliver/One-liter-bottle_1.png",
            name: "1 Litter Water",
            quantity: "1 X 12 Bottles",
            price: "₹ 85.00/-"
        },
        {
            imgSrc: "/assets/Image/Homepage/Bottle-Deliver/One-liter-bottle.png",
            name: "2 Litter Water",
            quantity: "1 X 9 Bottles",
            price: "₹ 140.00/-"
        },
        {
            imgSrc: "/assets/Image/Homepage/Bottle-Deliver/One-liter-bottle.png",
            name: "5 Litter Bottle",
            quantity: "1 X 1 Bottle",
            price: "₹ 60.00/-"
        },
    ];


    return (
        <section className='bottle-deliver'>
            <Container>
                <div className='mb-4'>
                    <h3 className='home-common-title'><span className='start-txttt'>B</span>ottles We Deliver</h3>
                </div>
                <Row>
                    <Col lg={6} md={12} sm={12} xs={12}>
                        <div className='we-deliver-firsttt'>
                            {products.map((product, index) => (
                                <div className='white-bgg' key={index}>
                                    <Row>
                                        <Col lg={12} md={12} sm={12} xs={12} className='mb-3'>
                                            <div>
                                                <div className="bottle_section">
                                                    <div className="bottlemain">
                                                        <Row>
                                                            <Col xxl={4} xl={4} lg={5} md={4} xs={4} className="my-auto">
                                                                <div className="bootleimg_div">
                                                                    <img
                                                                        src={process.env.PUBLIC_URL + product.imgSrc}
                                                                        className="bootleimgs"
                                                                        alt={product.name}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col xxl={8} xl={7} lg={7} md={8} xs={8} className="my-auto">
                                                                <div className="botltext">
                                                                    <p className="midtext">{product.name}</p>
                                                                    <div className="bottmtex_div">
                                                                        <p className="sub-txt">{product.quantity}</p>
                                                                        <p className="sub-txt">{product.price}</p>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </div>
                    </Col>
                    <Col lg={6} md={12} sm={12} xs={12}>
                        <img src={process.env.PUBLIC_URL + '/assets/Image/Homepage/Bottle-Deliver/Bottles We Deliver Section.png'} className='bottle-compo' />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default BottleWeDeliver




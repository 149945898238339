import React from "react";
import "./KeyParameter.css";
import { Card, Col, Container, Row } from "react-bootstrap";

const KeyParameter = () => {
    const crdimg = [
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/KeyParameter/key1.png",
            imge2: process.env.PUBLIC_URL + "/assets/Image/Homepage/KeyParameter/qqqqntyy.png",
            text: "Quality Check"
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/KeyParameter/key2.png",
            imge2: process.env.PUBLIC_URL + "/assets/Image/Homepage/KeyParameter/water-filter.png",
            text: "Filtration Level"
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/KeyParameter/key3.png",
            imge2: process.env.PUBLIC_URL + "/assets/Image/Homepage/KeyParameter/blood.png",
            text: "Composition"
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/KeyParameter/key4.png",
            imge2: process.env.PUBLIC_URL + "/assets/Image/Homepage/KeyParameter/microscope.png",
            text: "Lab Control"
        },
    ];
    return (
        <section className="key-parameter">
            <Container>
                <div className="mb-4">
                    <h3 className="home-common-title">
                        <span className="start-txttt">K</span>ey Parameters
                    </h3>
                </div>
                <Row>
                    <Col lg={12} xs={12}>
                        <p className="sub-txtttt-spring">
                            We pride ourselves on our standard parameters
                        </p>
                    </Col>
                    {crdimg.map((item, index) => (
                    <Col lg={3} md={4} sm={6} xs={12}>
                        <div className="keycard">
                           
                            <div className="changes_mains">
                                <div className="change_div">
                                    <img
                                        src={
                                           item.image
                                        }
                                        className="change_img"
                                    />
                                </div>
                                <div className="png_main_div">
                                    <div className="pngsub_div">
                                        <div className="png_div">
                                            <img
                                                src={
                                                   item.imge2
                                                }
                                                className="png_img"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h5 className="qualitytext">{item.text}</h5>
                            </div>
                            <div className="fixback_div">
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/assets/Image/Homepage/KeyParameter/blue-bg.png"
                                    }
                                    className="fix_img"
                                />
                            </div>
                        </div>
                    </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};

export default KeyParameter;

import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import './ServicingAcross.css'

const ServicingAcross = () => {
    return (
        <section className='servicing-across'>
            <Container>
                <div className='mb-4'>
                    <h3 className='home-common-title'><span className='start-txttt'>S</span>erving Across</h3>
                </div>
                <Row>
                    <Col lg={2} md={3} sm={12} xs={12} >
                        <div className='branch-main'>
                            <p className='branch-name'>Pune</p>
                            <p className='branch-name'>Solapur</p>
                            <p className='branch-name'>Ahmednagar</p>
                            <p className='branch-name'>Panvel</p>
                            <p className='branch-name'>Satara</p>
                        </div>
                    </Col>
                    <Col lg={5} md={1} sm={12} xs={12} >
                        <div className='servicing-main-imggg'>
                            <img src={process.env.PUBLIC_URL+'/assets/Image/Homepage/Servicing_Across/ring.png' } className='ring-image' />
                            <img src={process.env.PUBLIC_URL + '/assets/Image/Homepage/Servicing_Across/bottle.png'} className='bottle-image' />
                        </div>                      
                    </Col>
                    <Col lg={5} md={8} sm={12} xs={12} >
                        <div className='white-bgggg'>
                            <p className='text-center sub-txt double-txttt'>We will deliver water to your home. <br /> Just fill the delivery form below.</p>
                            <Form>
                                <Form.Group className="mb-3" controlId="formName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter your name" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formAddress">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control type="text" placeholder="Enter your address" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formPhoneNumber">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control type="tel" placeholder="Enter your phone number" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formQuantity">
                                    <Form.Label>Quantity</Form.Label>
                                    <Form.Control type="number" placeholder="Enter quantity" />
                                </Form.Group>
                                <div className='text-center mt-5'>
                                    <button className='common-btn'><span className='common-span'>Place Order</span></button>
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default ServicingAcross
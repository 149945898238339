import React from 'react'
import GetInTouch from './GetInTouch/GetInTouch'
import FindUs from './FindUs/FindUs'

const ContactUs = () => {
    return (
        <>
            <GetInTouch />
            <FindUs/>
        </>

    )
}

export default ContactUs
import React from 'react'
import './PurityCard.css'
function PurityCard({item}) {
  return (
    <>
    <section className='puritycard_section'>
    <div className="wtrmain_div">
                  <div className="wtrimmg">
                    <img src={item.image} className="war_img" />
                  </div>
                  <p className="midtext mt-1 mb-1">{item.name}</p>
                  <p className="sub-txt">{item.text}</p>
                </div>
    </section>
    </>
  )
}

export default PurityCard
import React from 'react'
import './WhyChoose.css'
import { Col, Container, Row } from 'react-bootstrap'
import WaterDetails from '../../CommonComponents/WaterDetails/WaterDetails';


function WhyChoose() {
    const data = [
      {
        image: process.env.PUBLIC_URL + "/assets/Image/service/water.png",
        image1: process.env.PUBLIC_URL + "/assets/Image/service/watergallery.png",
        startcaracter:"S",
        text1:"Cras sagittis lectus ut ornare gravida. Integer in neque nibh. Vestibulum aliquam egestas interdum nisl. ",
        subtitle:"ystem Repair",
        },
      {
        image: process.env.PUBLIC_URL + "/assets/Image/service/glasswater.png",
        image1: process.env.PUBLIC_URL + "/assets/Image/service/deliver.png",
        text1: "Cras sagittis lectus ut ornare gravida. Integer in neque nibh. Vestibulum aliquam egestas interdum nisl. ",
        startcaracter: "W",
        subtitle: "ater Testing",
      },
      {
        image: process.env.PUBLIC_URL + "/assets/Image/service/bottleglass.png",
        image1: process.env.PUBLIC_URL + "/assets/Image/service/girlbottle.png",
        text1: "Cras sagittis lectus ut ornare gravida. Integer in neque nibh. Vestibulum aliquam egestas interdum nisl. ",
        startcaracter: "W",
        subtitle: "ater Trailers",
      },
      {
        image: process.env.PUBLIC_URL + "/assets/Image/service/tapwater.png",
        image1: process.env.PUBLIC_URL + "/assets/Image/service/deliver.png",
        text1: "Cras sagittis lectus ut ornare gravida. Integer in neque nibh. Vestibulum aliquam egestas interdum nisl. ",
        startcaracter: "C",
        subtitle: "osmetic Dentistry",
      },
      {
        image: process.env.PUBLIC_URL + "/assets/Image/service/themb.png",
        image1: process.env.PUBLIC_URL + "/assets/Image/service/glasswat.png",
        text1: "Cras sagittis lectus ut ornare gravida. Integer in neque nibh. Vestibulum aliquam egestas interdum nisl. ",
        startcaracter: "W",
        subtitle: "ater Dispenser",
      },
      {
        image: process.env.PUBLIC_URL + "/assets/Image/service/waterdrop.png",
        image1: process.env.PUBLIC_URL + "/assets/Image/service/waterbottle.png",
        text1: "Cras sagittis lectus ut ornare gravida. Integer in neque nibh. Vestibulum aliquam egestas interdum nisl. ",
        startcaracter: "B",
        subtitle: "ottled Water",
      },
        
      ];
  return (
    <>
     <section className='whychoose_section'>
      <Container>
        <div className='whychoose_div_main'>
          <div className='title_divs'>
              <h2 className='home-common-title'>WHY CHOOSE US</h2>
          <p className='sub-txt text-center mb-0'>A Trusted Name In Bottled Water Industry</p>
          </div>
          <div className='whychooseban'>
            <Row>
            {data.map((item, index) => (
              <Col xxl={4} xl={4} lg={4} md={6} sm={12} className='mb-5'>
              <div>
                <WaterDetails data={item}/>
              </div>
              </Col>
               ))}

            </Row>
          </div>
        </div>
      </Container>
    </section>
    </>
    
  )
}

export default WhyChoose
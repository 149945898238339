import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import MainBlog from './MainBlog/MainBlog'
import RecentNews from './RecentNews/RecentNews'

const Blog = () => {
    return (
        <section>
            <Container>
                <Row>
                    <Col lg={9} md={8} sm={12} xs={12}>
                        <MainBlog/>
                    </Col>
                    <Col lg={3} md={4} sm={12} xs={12}>
                        <RecentNews/>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Blog
import React, { useEffect, useRef } from 'react'
import './HowItWorks.css'
import { Col, Container, Row } from 'react-bootstrap'
import LazyLoad from 'react-lazyload'

const HowItWorks = () => {
    const videoRef = useRef(null)

    const handleScroll = () => {
        if (videoRef.current) {
            videoRef.current.pause()
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        <section className='how-it-works'>
            <Container>
                <div className='mb-4'>
                    <h3 className='home-common-title'><span className='start-txttt'>H</span>ow it Works</h3>
                </div>
                <Row>
                    <Col lg={12} xs={12}>
                        <div className='video-div'>
                            <LazyLoad className='text-center'>
                                <video
                                    className='video-works'
                                    autoPlay
                                    loop
                                    muted
                                    controls
                                    ref={videoRef}>
                                    <source src={process.env.PUBLIC_URL + '/assets/Videos/banner1.mp4'}></source>
                                </video>
                            </LazyLoad>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default HowItWorks
